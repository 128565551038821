import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: { input: string; output: string }
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: { input: string; output: string }
  /** The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For example, "**username@example.com**" is a valid Email address. */
  AWSEmail: { input: string; output: string }
  AWSIPAddress: { input: string; output: string }
  AWSJSON: { input: string; output: string }
  AWSPhone: { input: string; output: string }
  AWSTime: { input: string; output: string }
  AWSTimestamp: { input: string; output: string }
  /** The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**" or "**www.amazon.com**" are considered invalid. URLs which contain double slashes (two consecutive forward slashes) in their path are also considered invalid. */
  AWSURL: { input: string; output: string }
  BigInt: { input: number; output: number }
  Double: { input: number; output: number }
}

export enum BillingType {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type BuyCreditsInput = {
  quantity: Scalars['Int']['input']
}

export type BuyCreditsResponse = {
  __typename: 'BuyCreditsResponse'
  url: Scalars['AWSURL']['output']
}

export type CloudTag = Tag & {
  __typename: 'CloudTag'
  count: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type Configuration = {
  __typename: 'Configuration'
  tags: Array<Scalars['String']['output']>
  useAnd: Scalars['Boolean']['output']
}

export type Error = {
  __typename: 'Error'
  code?: Maybe<Scalars['Int']['output']>
  message?: Maybe<Scalars['String']['output']>
}

export enum FlickrPermissions {
  Delete = 'delete',
  Read = 'read',
  Write = 'write',
}

export type Group = {
  __typename: 'Group'
  configuration: Configuration
  eighteenPlus: Scalars['Boolean']['output']
  icon: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitationOnly: Scalars['Boolean']['output']
  isMember: Scalars['Boolean']['output']
  lastActivity?: Maybe<Scalars['AWSDateTime']['output']>
  members: Scalars['Int']['output']
  photoLimitOptOut?: Maybe<Scalars['Boolean']['output']>
  poolCount: Scalars['Int']['output']
  privacy: Scalars['Int']['output']
  rules?: Maybe<Scalars['String']['output']>
  safety: GroupSafety
  tagSuggestions: Array<TagSuggestion>
  throttle: Throttle
  title: Scalars['String']['output']
}

export enum GroupSafety {
  Moderate = 'moderate',
  Restricted = 'restricted',
  Safe = 'safe',
}

export type GroupTag = {
  __typename: 'GroupTag'
  group?: Maybe<Group>
  groupId: Scalars['String']['output']
  tags: Array<Scalars['String']['output']>
  useAnd: Scalars['Boolean']['output']
  userId: Scalars['String']['output']
}

export type JoinGroupInput = {
  acceptRules?: InputMaybe<Scalars['Boolean']['input']>
  groupId: Scalars['String']['input']
  message?: InputMaybe<Scalars['String']['input']>
}

export type LastPayment = {
  __typename: 'LastPayment'
  amount: Scalars['Float']['output']
  currency: Scalars['String']['output']
  date: Scalars['AWSDate']['output']
}

export type LeaveGroupInput = {
  groupId?: InputMaybe<Scalars['String']['input']>
}

export type Log = {
  group: Group
  sk: Scalars['ID']['output']
  timestamp: Scalars['AWSDateTime']['output']
}

export type MessagesAvailable = {
  __typename: 'MessagesAvailable'
  x: Scalars['Int']['output']
  y: Scalars['Int']['output']
}

export type Mutation = {
  __typename: 'Mutation'
  buyCreditsLink: BuyCreditsResponse
  cancelSyncPhoto: SyncStatus
  configureGroup: Group
  deleteScheduledPhoto?: Maybe<ScheduledPhoto>
  joinGroup: Group
  leaveGroup?: Maybe<GroupTag>
  oauthVerify: User
  syncPhoto: SyncStatus
  unsubscribe?: Maybe<SubscriptionState>
  updatePhotoSyncStatus?: Maybe<SyncStatus>
  updatePhotoTags?: Maybe<UpdatePhotoTagsResponse>
  updateUserCredits?: Maybe<User>
  upsertUser: User
}

export type MutationBuyCreditsLinkArgs = {
  input: BuyCreditsInput
}

export type MutationCancelSyncPhotoArgs = {
  id: Scalars['String']['input']
}

export type MutationConfigureGroupArgs = {
  input: UpdateGroupTagsList
}

export type MutationDeleteScheduledPhotoArgs = {
  groupId: Scalars['String']['input']
  photoId: Scalars['String']['input']
}

export type MutationJoinGroupArgs = {
  input: JoinGroupInput
}

export type MutationLeaveGroupArgs = {
  input?: InputMaybe<LeaveGroupInput>
}

export type MutationOauthVerifyArgs = {
  oauthToken: Scalars['String']['input']
  oauthVerifier: Scalars['String']['input']
}

export type MutationSyncPhotoArgs = {
  id: Scalars['String']['input']
}

export type MutationUnsubscribeArgs = {
  input?: InputMaybe<UnsubscribeInput>
}

export type MutationUpdatePhotoSyncStatusArgs = {
  error?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  scheduled?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<SyncState>
  success?: InputMaybe<Scalars['Int']['input']>
  total?: InputMaybe<Scalars['Int']['input']>
  userId?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdatePhotoTagsArgs = {
  input?: InputMaybe<UpdatePhotoInput>
}

export type MutationUpdateUserCreditsArgs = {
  credits: Scalars['Int']['input']
  userId: Scalars['ID']['input']
}

export type MutationUpsertUserArgs = {
  input: UserInput
}

export type MutationResponse = {
  error?: Maybe<Error>
  status?: Maybe<Scalars['Boolean']['output']>
}

export type NextPayment = {
  __typename: 'NextPayment'
  amount: Scalars['Float']['output']
  currency: Scalars['String']['output']
  date: Scalars['AWSDate']['output']
}

export type OauthUrlInput = {
  oauthCallback: Scalars['AWSURL']['input']
  permissions: FlickrPermissions
}

export type PaginatedGroupTags = {
  __typename: 'PaginatedGroupTags'
  groupTags: Array<GroupTag>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type PaginatedScheduledPhotos = {
  __typename: 'PaginatedScheduledPhotos'
  nextToken?: Maybe<Scalars['String']['output']>
  scheduledPhotos: Array<ScheduledPhoto>
}

export type PaymentInformation = {
  __typename: 'PaymentInformation'
  cardType?: Maybe<Scalars['String']['output']>
  expiryDate?: Maybe<Scalars['String']['output']>
  lastFourDigits?: Maybe<Scalars['String']['output']>
  paymentMethod: Scalars['String']['output']
}

export type Photo = {
  __typename: 'Photo'
  id: Scalars['ID']['output']
  isPublic?: Maybe<Scalars['Boolean']['output']>
  sharedGroups?: Maybe<Scalars['Int']['output']>
  syncStatus?: Maybe<SyncStatus>
  tags: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  totalFavs?: Maybe<Scalars['Int']['output']>
  urlM: Scalars['String']['output']
  urlSq: Scalars['String']['output']
  userId: Scalars['String']['output']
  views?: Maybe<Scalars['Int']['output']>
}

export type PhotoFilter = {
  groupId?: InputMaybe<Scalars['String']['input']>
  onlyPublic?: InputMaybe<Scalars['Boolean']['input']>
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
  tags?: InputMaybe<Scalars['String']['input']>
  useAnd?: InputMaybe<Scalars['Boolean']['input']>
}

export type PhotoLogsFilter = {
  count: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
  photoId: Scalars['String']['input']
}

export type PhotoLogsPagination = {
  __typename: 'PhotoLogsPagination'
  nextToken?: Maybe<Scalars['String']['output']>
  photoLogs: Array<Log>
}

export type Plan = {
  __typename: 'Plan'
  billingPeriod: Scalars['Int']['output']
  billingType: BillingType
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Query = {
  __typename: 'Query'
  getPhotoSyncStatus: SyncStatus
  getQueueMessagesAvailable: Array<MessagesAvailable>
  group?: Maybe<Group>
  groupTagsList: PaginatedGroupTags
  me: User
  myGroups: RelatedGroupsPagination
  myPhotos: RelatedPhotosPagination
  myPhotosTagCloud: Array<CloudTag>
  oauthUrl: Scalars['AWSURL']['output']
  photoLogs: PhotoLogsPagination
  scheduledPhotos: PaginatedScheduledPhotos
  searchGroups: RelatedGroupsPagination
  suggestedTags: Array<SuggestedTag>
}

export type QueryGetPhotoSyncStatusArgs = {
  photoId: Scalars['String']['input']
}

export type QueryGroupArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGroupTagsListArgs = {
  count?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  tags: Array<Scalars['String']['input']>
}

export type QueryMyGroupsArgs = {
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
}

export type QueryMyPhotosArgs = {
  input?: InputMaybe<PhotoFilter>
}

export type QueryMyPhotosTagCloudArgs = {
  input: TagCloudFilter
}

export type QueryOauthUrlArgs = {
  input: OauthUrlInput
}

export type QueryPhotoLogsArgs = {
  input?: InputMaybe<PhotoLogsFilter>
}

export type QueryScheduledPhotosArgs = {
  input?: InputMaybe<ScheduledPhotosFilter>
}

export type QuerySearchGroupsArgs = {
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
  text: Scalars['String']['input']
  userId?: InputMaybe<Scalars['String']['input']>
}

export type QuerySuggestedTagsArgs = {
  groupId: Scalars['String']['input']
}

export type RelatedGroupsPagination = {
  __typename: 'RelatedGroupsPagination'
  groups: Array<Group>
  page: Scalars['Int']['output']
  pages?: Maybe<Scalars['Int']['output']>
  perPage: Scalars['Int']['output']
  total?: Maybe<Scalars['Int']['output']>
}

export type RelatedPhotosPagination = {
  __typename: 'RelatedPhotosPagination'
  page: Scalars['Int']['output']
  pages: Scalars['Int']['output']
  perPage: Scalars['Int']['output']
  photos: Array<Photo>
  total: Scalars['Int']['output']
}

export type ScheduledPhoto = {
  __typename: 'ScheduledPhoto'
  code?: Maybe<Scalars['String']['output']>
  group?: Maybe<Group>
  groupId: Scalars['String']['output']
  photo?: Maybe<Photo>
  photoId: Scalars['String']['output']
  scheduledAt?: Maybe<Scalars['String']['output']>
  userId: Scalars['String']['output']
}

export type ScheduledPhotoLog = Log & {
  __typename: 'ScheduledPhotoLog'
  group: Group
  scheduledAt: Scalars['AWSDate']['output']
  sk: Scalars['ID']['output']
  throttle: Throttle
  timestamp: Scalars['AWSDateTime']['output']
}

export type ScheduledPhotosFilter = {
  count: Scalars['Int']['input']
  groupId?: InputMaybe<Scalars['String']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  photoId?: InputMaybe<Scalars['String']['input']>
  scheduledAt: Scalars['String']['input']
}

export type SharePhotoErrorLog = Log & {
  __typename: 'SharePhotoErrorLog'
  errorCode: Scalars['Int']['output']
  group: Group
  sk: Scalars['ID']['output']
  timestamp: Scalars['AWSDateTime']['output']
}

export type SharePhotoSuccessLog = Log & {
  __typename: 'SharePhotoSuccessLog'
  group: Group
  sk: Scalars['ID']['output']
  timestamp: Scalars['AWSDateTime']['output']
}

export type Subscription = {
  __typename: 'Subscription'
  onUpdatePhotoSyncStatus?: Maybe<SyncStatus>
  onUpdateUserCredits?: Maybe<User>
}

export type SubscriptionOnUpdatePhotoSyncStatusArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionOnUpdateUserCreditsArgs = {
  userId: Scalars['ID']['input']
}

export type SubscriptionModifier = {
  __typename: 'SubscriptionModifier'
  amount: Scalars['String']['output']
  currency: Scalars['String']['output']
  description: Scalars['String']['output']
  isRecurring: Scalars['Boolean']['output']
}

export type SubscriptionState = {
  __typename: 'SubscriptionState'
  cancelUrl: Scalars['AWSURL']['output']
  id: Scalars['ID']['output']
  lastPayment: LastPayment
  modifiers: Array<SubscriptionModifier>
  nextPayment?: Maybe<NextPayment>
  paymentInformation?: Maybe<PaymentInformation>
  plan: Plan
  planId: Scalars['Int']['output']
  signupDate?: Maybe<Scalars['String']['output']>
  state: SubscriptionStatus
  updateUrl: Scalars['AWSURL']['output']
  userEmail: Scalars['AWSEmail']['output']
  userId: Scalars['Int']['output']
}

export enum SubscriptionStatus {
  Active = 'active',
  Deleted = 'deleted',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
}

export type SuggestedTag = Tag & {
  __typename: 'SuggestedTag'
  count: Scalars['Int']['output']
  name: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export enum SyncState {
  Cancelled = 'cancelled',
  Finished = 'finished',
  Running = 'running',
  Waiting = 'waiting',
}

export type SyncStatus = {
  __typename: 'SyncStatus'
  error: Scalars['Int']['output']
  id: Scalars['ID']['output']
  scheduled: Scalars['Int']['output']
  state: SyncState
  success: Scalars['Int']['output']
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>
  total?: Maybe<Scalars['Int']['output']>
}

export type Tag = {
  count?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type TagCloudFilter = {
  count: Scalars['Int']['input']
}

export type TagSuggestion = {
  __typename: 'TagSuggestion'
  explanation: Scalars['String']['output']
  matchingPhotos: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type Throttle = {
  __typename: 'Throttle'
  count?: Maybe<Scalars['Int']['output']>
  mode?: Maybe<Scalars['String']['output']>
  remaining?: Maybe<Scalars['Int']['output']>
}

export type UnsubscribeInput = {
  message?: InputMaybe<Scalars['String']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
}

export type UpdateGroupTagsList = {
  groupId: Scalars['String']['input']
  tags: Array<Scalars['String']['input']>
  useAnd: Scalars['Boolean']['input']
}

export type UpdatePhotoInput = {
  photoId?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/**
 *   type LeaveGroupResponse implements MutationResponse {
 *    status: Boolean
 *    error: Error
 *    groupTag: GroupTag
 *  }
 */
export type UpdatePhotoTagsResponse = MutationResponse & {
  __typename: 'UpdatePhotoTagsResponse'
  error?: Maybe<Error>
  photo?: Maybe<Photo>
  status?: Maybe<Scalars['Boolean']['output']>
}

export type User = {
  __typename: 'User'
  buddyIcon: Scalars['String']['output']
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>
  /**   cognito autogenerated ID */
  email: Scalars['AWSEmail']['output']
  permissions?: Maybe<FlickrPermissions>
  realName?: Maybe<Scalars['String']['output']>
  shares: UserShares
  subscriptionId?: Maybe<Scalars['Int']['output']>
  subscriptionState?: Maybe<SubscriptionState>
  subscriptionValidUntil?: Maybe<Scalars['AWSDate']['output']>
  totalGroups?: Maybe<Scalars['Int']['output']>
  totalTags?: Maybe<Scalars['Int']['output']>
  usefulTags?: Maybe<Scalars['Int']['output']>
  userId: Scalars['ID']['output']
  userName?: Maybe<Scalars['String']['output']>
  valid: Scalars['Boolean']['output']
}

export type UserInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>
  realName?: InputMaybe<Scalars['String']['input']>
  subscriptionId?: InputMaybe<Scalars['Int']['input']>
  userName?: InputMaybe<Scalars['String']['input']>
}

export type UserShares = {
  __typename: 'UserShares'
  amount: Scalars['Int']['output']
  credits: Scalars['Int']['output']
  lastShareAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export type SubscriptionStateFrFragment = {
  __typename: 'SubscriptionState'
  id: string
  state: SubscriptionStatus
  userId: number
  userEmail: string
  planId: number
  signupDate?: string | null
  updateUrl: string
  cancelUrl: string
  plan: { __typename: 'Plan'; name: string; billingType: BillingType; billingPeriod: number }
  modifiers: Array<{
    __typename: 'SubscriptionModifier'
    amount: string
    currency: string
    isRecurring: boolean
    description: string
  }>
  lastPayment: { __typename: 'LastPayment'; amount: number; date: string; currency: string }
  paymentInformation?: {
    __typename: 'PaymentInformation'
    paymentMethod: string
    lastFourDigits?: string | null
    expiryDate?: string | null
    cardType?: string | null
  } | null
  nextPayment?: { __typename: 'NextPayment'; amount: number; date: string; currency: string } | null
}

export type OauthVerifyMutationVariables = Exact<{
  oauthToken: Scalars['String']['input']
  oauthVerifier: Scalars['String']['input']
}>

export type OauthVerifyMutation = {
  __typename: 'Mutation'
  oauthVerify: {
    __typename: 'User'
    userId: string
    realName?: string | null
    userName?: string | null
    permissions?: FlickrPermissions | null
    valid: boolean
    buddyIcon: string
  }
}

export type ConfigureGroupMutationVariables = Exact<{
  groupId: Scalars['String']['input']
  tags: Array<Scalars['String']['input']> | Scalars['String']['input']
  useAnd: Scalars['Boolean']['input']
}>

export type ConfigureGroupMutation = {
  __typename: 'Mutation'
  configureGroup: {
    __typename: 'Group'
    id: string
    configuration: { __typename: 'Configuration'; tags: Array<string>; useAnd: boolean }
  }
}

export type DeleteScheduledPhotoMutationVariables = Exact<{
  groupId: Scalars['String']['input']
  photoId: Scalars['String']['input']
}>

export type DeleteScheduledPhotoMutation = {
  __typename: 'Mutation'
  deleteScheduledPhoto?: {
    __typename: 'ScheduledPhoto'
    userId: string
    groupId: string
    photoId: string
    scheduledAt?: string | null
    photo?: {
      __typename: 'Photo'
      id: string
      title: string
      urlSq: string
      isPublic?: boolean | null
    } | null
    group?: { __typename: 'Group'; id: string; title: string } | null
  } | null
}

export type JoinGroupMutationVariables = Exact<{
  input: JoinGroupInput
}>

export type JoinGroupMutation = {
  __typename: 'Mutation'
  joinGroup: {
    __typename: 'Group'
    id: string
    isMember: boolean
    configuration: { __typename: 'Configuration'; tags: Array<string>; useAnd: boolean }
  }
}

export type UpdatePhotoTagsMutationVariables = Exact<{
  photoId: Scalars['String']['input']
  tags?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type UpdatePhotoTagsMutation = {
  __typename: 'Mutation'
  updatePhotoTags?: {
    __typename: 'UpdatePhotoTagsResponse'
    photo?: { __typename: 'Photo'; id: string; tags: Array<string> } | null
    error?: { __typename: 'Error'; code?: number | null; message?: string | null } | null
  } | null
}

export type UpsertUserMutationVariables = Exact<{
  input: UserInput
}>

export type UpsertUserMutation = {
  __typename: 'Mutation'
  upsertUser: {
    __typename: 'User'
    userId: string
    email: string
    realName?: string | null
    userName?: string | null
  }
}

export type SyncPhotoMutationVariables = Exact<{
  photoId: Scalars['String']['input']
}>

export type SyncPhotoMutation = {
  __typename: 'Mutation'
  syncPhoto: {
    __typename: 'SyncStatus'
    id: string
    state: SyncState
    total?: number | null
    success: number
    scheduled: number
    error: number
    timestamp?: string | null
  }
}

export type CancelSyncPhotoMutationVariables = Exact<{
  photoId: Scalars['String']['input']
}>

export type CancelSyncPhotoMutation = {
  __typename: 'Mutation'
  cancelSyncPhoto: {
    __typename: 'SyncStatus'
    id: string
    state: SyncState
    total?: number | null
    success: number
    scheduled: number
    error: number
    timestamp?: string | null
  }
}

export type UpdateUserSubscriptionMutationVariables = Exact<{
  input: UserInput
}>

export type UpdateUserSubscriptionMutation = {
  __typename: 'Mutation'
  upsertUser: {
    __typename: 'User'
    userId: string
    subscriptionId?: number | null
    subscriptionState?: {
      __typename: 'SubscriptionState'
      id: string
      state: SubscriptionStatus
      userId: number
      userEmail: string
      planId: number
      signupDate?: string | null
      updateUrl: string
      cancelUrl: string
      plan: { __typename: 'Plan'; name: string; billingType: BillingType; billingPeriod: number }
      modifiers: Array<{
        __typename: 'SubscriptionModifier'
        amount: string
        currency: string
        isRecurring: boolean
        description: string
      }>
      lastPayment: { __typename: 'LastPayment'; amount: number; date: string; currency: string }
      paymentInformation?: {
        __typename: 'PaymentInformation'
        paymentMethod: string
        lastFourDigits?: string | null
        expiryDate?: string | null
        cardType?: string | null
      } | null
      nextPayment?: {
        __typename: 'NextPayment'
        amount: number
        date: string
        currency: string
      } | null
    } | null
  }
}

export type UnsubscribeMutationVariables = Exact<{
  input?: InputMaybe<UnsubscribeInput>
}>

export type UnsubscribeMutation = {
  __typename: 'Mutation'
  unsubscribe?: {
    __typename: 'SubscriptionState'
    id: string
    state: SubscriptionStatus
    userId: number
    userEmail: string
    planId: number
    signupDate?: string | null
    updateUrl: string
    cancelUrl: string
    plan: { __typename: 'Plan'; name: string; billingType: BillingType; billingPeriod: number }
    modifiers: Array<{
      __typename: 'SubscriptionModifier'
      amount: string
      currency: string
      isRecurring: boolean
      description: string
    }>
    lastPayment: { __typename: 'LastPayment'; amount: number; date: string; currency: string }
    paymentInformation?: {
      __typename: 'PaymentInformation'
      paymentMethod: string
      lastFourDigits?: string | null
      expiryDate?: string | null
      cardType?: string | null
    } | null
    nextPayment?: {
      __typename: 'NextPayment'
      amount: number
      date: string
      currency: string
    } | null
  } | null
}

export type BuyCreditsLinkMutationVariables = Exact<{
  input: BuyCreditsInput
}>

export type BuyCreditsLinkMutation = {
  __typename: 'Mutation'
  buyCreditsLink: { __typename: 'BuyCreditsResponse'; url: string }
}

export type TagCloudQueryVariables = Exact<{ [key: string]: never }>

export type TagCloudQuery = {
  __typename: 'Query'
  groupTagsList: {
    __typename: 'PaginatedGroupTags'
    nextToken?: string | null
    groupTags: Array<{
      __typename: 'GroupTag'
      groupId: string
      userId: string
      tags: Array<string>
    }>
  }
}

export type MyPhotosQueryVariables = Exact<{
  input?: InputMaybe<PhotoFilter>
}>

export type MyPhotosQuery = {
  __typename: 'Query'
  myPhotos: {
    __typename: 'RelatedPhotosPagination'
    total: number
    pages: number
    page: number
    perPage: number
    photos: Array<{
      __typename: 'Photo'
      id: string
      userId: string
      title: string
      views?: number | null
      urlSq: string
      isPublic?: boolean | null
      sharedGroups?: number | null
      totalFavs?: number | null
      tags: Array<string>
      syncStatus?: {
        __typename: 'SyncStatus'
        id: string
        state: SyncState
        total?: number | null
        scheduled: number
        success: number
        error: number
        timestamp?: string | null
      } | null
    }>
  }
}

export type MyPhotoListQueryVariables = Exact<{
  input?: InputMaybe<PhotoFilter>
}>

export type MyPhotoListQuery = {
  __typename: 'Query'
  myPhotos: {
    __typename: 'RelatedPhotosPagination'
    total: number
    pages: number
    photos: Array<{ __typename: 'Photo'; id: string; title: string; urlSq: string }>
  }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename: 'Query'
  me: {
    __typename: 'User'
    userId: string
    email: string
    valid: boolean
    buddyIcon: string
    realName?: string | null
    userName?: string | null
    permissions?: FlickrPermissions | null
    shares: { __typename: 'UserShares'; credits: number }
  }
}

export type OauthUrlQueryVariables = Exact<{
  input: OauthUrlInput
}>

export type OauthUrlQuery = { __typename: 'Query'; oauthUrl: string }

export type CheckUserIsValidQueryVariables = Exact<{ [key: string]: never }>

export type CheckUserIsValidQuery = {
  __typename: 'Query'
  me: {
    __typename: 'User'
    userId: string
    valid: boolean
    buddyIcon: string
    realName?: string | null
    userName?: string | null
  }
}

export type MyGroupsQueryVariables = Exact<{
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
}>

export type MyGroupsQuery = {
  __typename: 'Query'
  myGroups: {
    __typename: 'RelatedGroupsPagination'
    total?: number | null
    perPage: number
    pages?: number | null
    page: number
    groups: Array<{
      __typename: 'Group'
      id: string
      title: string
      icon: string
      isMember: boolean
      members: number
      poolCount: number
      safety: GroupSafety
      eighteenPlus: boolean
      photoLimitOptOut?: boolean | null
      rules?: string | null
      lastActivity?: string | null
      throttle: {
        __typename: 'Throttle'
        mode?: string | null
        count?: number | null
        remaining?: number | null
      }
      configuration: { __typename: 'Configuration'; useAnd: boolean; tags: Array<string> }
    }>
  }
}

export type TagSuggestionsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars['String']['input']>
}>

export type TagSuggestionsQuery = {
  __typename: 'Query'
  group?: {
    __typename: 'Group'
    id: string
    tagSuggestions: Array<{
      __typename: 'TagSuggestion'
      explanation: string
      name: string
      matchingPhotos: number
    }>
  } | null
}

export type ScheduledPhotosQueryVariables = Exact<{
  input?: InputMaybe<ScheduledPhotosFilter>
}>

export type ScheduledPhotosQuery = {
  __typename: 'Query'
  scheduledPhotos: {
    __typename: 'PaginatedScheduledPhotos'
    nextToken?: string | null
    scheduledPhotos: Array<{
      __typename: 'ScheduledPhoto'
      userId: string
      groupId: string
      photoId: string
      scheduledAt?: string | null
      photo?: {
        __typename: 'Photo'
        id: string
        title: string
        urlSq: string
        isPublic?: boolean | null
        tags: Array<string>
      } | null
      group?: { __typename: 'Group'; id: string; title: string } | null
    }>
  }
}

export type GroupTagsListQueryVariables = Exact<{
  tags: Array<Scalars['String']['input']> | Scalars['String']['input']
  count?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type GroupTagsListQuery = {
  __typename: 'Query'
  groupTagsList: {
    __typename: 'PaginatedGroupTags'
    nextToken?: string | null
    groupTags: Array<{
      __typename: 'GroupTag'
      groupId: string
      group?: {
        __typename: 'Group'
        id: string
        title: string
        icon: string
        isMember: boolean
        members: number
        poolCount: number
        photoLimitOptOut?: boolean | null
        lastActivity?: string | null
        configuration: { __typename: 'Configuration'; useAnd: boolean; tags: Array<string> }
        throttle: {
          __typename: 'Throttle'
          mode?: string | null
          count?: number | null
          remaining?: number | null
        }
      } | null
    }>
  }
}

export type SearchGroupsQueryVariables = Exact<{
  text: Scalars['String']['input']
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
  userId?: InputMaybe<Scalars['String']['input']>
}>

export type SearchGroupsQuery = {
  __typename: 'Query'
  searchGroups: {
    __typename: 'RelatedGroupsPagination'
    page: number
    pages?: number | null
    perPage: number
    total?: number | null
    groups: Array<{
      __typename: 'Group'
      id: string
      title: string
      icon: string
      poolCount: number
      members: number
      invitationOnly: boolean
      photoLimitOptOut?: boolean | null
      isMember: boolean
      rules?: string | null
      throttle: {
        __typename: 'Throttle'
        mode?: string | null
        count?: number | null
        remaining?: number | null
      }
      configuration: { __typename: 'Configuration'; tags: Array<string>; useAnd: boolean }
    }>
  }
}

export type SuggestedTagsQueryVariables = Exact<{
  groupId: Scalars['String']['input']
}>

export type SuggestedTagsQuery = {
  __typename: 'Query'
  suggestedTags: Array<{ __typename: 'SuggestedTag'; name: string; count: number; total: number }>
}

export type PhotoLogsQueryVariables = Exact<{
  input: PhotoLogsFilter
}>

export type PhotoLogsQuery = {
  __typename: 'Query'
  photoLogs: {
    __typename: 'PhotoLogsPagination'
    nextToken?: string | null
    photoLogs: Array<
      | {
          __typename: 'ScheduledPhotoLog'
          scheduledAt: string
          timestamp: string
          sk: string
          group: { __typename: 'Group'; id: string; title: string; icon: string }
          throttle: {
            __typename: 'Throttle'
            mode?: string | null
            count?: number | null
            remaining?: number | null
          }
        }
      | {
          __typename: 'SharePhotoErrorLog'
          errorCode: number
          timestamp: string
          sk: string
          group: { __typename: 'Group'; id: string; title: string; icon: string }
        }
      | {
          __typename: 'SharePhotoSuccessLog'
          timestamp: string
          sk: string
          group: { __typename: 'Group'; id: string; title: string; icon: string }
        }
    >
  }
}

export type GroupFragment = { __typename: 'Group'; id: string; title: string; icon: string }

export type HydrateGroupQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars['String']['input']>
}>

export type HydrateGroupQuery = {
  __typename: 'Query'
  hydrateGroup?: {
    __typename: 'Group'
    id: string
    photoLimitOptOut?: boolean | null
    rules?: string | null
    lastActivity?: string | null
  } | null
}

export type GetPhotoSyncStatusQueryVariables = Exact<{
  photoId: Scalars['String']['input']
}>

export type GetPhotoSyncStatusQuery = {
  __typename: 'Query'
  getPhotoSyncStatus: {
    __typename: 'SyncStatus'
    id: string
    state: SyncState
    total?: number | null
    scheduled: number
    success: number
    error: number
    timestamp?: string | null
  }
}

export type UserSubscriptionInformationQueryVariables = Exact<{ [key: string]: never }>

export type UserSubscriptionInformationQuery = {
  __typename: 'Query'
  me: {
    __typename: 'User'
    userId: string
    email: string
    subscriptionId?: number | null
    subscriptionValidUntil?: string | null
    shares: { __typename: 'UserShares'; credits: number }
    subscriptionState?: {
      __typename: 'SubscriptionState'
      id: string
      state: SubscriptionStatus
      userId: number
      userEmail: string
      planId: number
      signupDate?: string | null
      updateUrl: string
      cancelUrl: string
      plan: { __typename: 'Plan'; name: string; billingType: BillingType; billingPeriod: number }
      modifiers: Array<{
        __typename: 'SubscriptionModifier'
        amount: string
        currency: string
        isRecurring: boolean
        description: string
      }>
      lastPayment: { __typename: 'LastPayment'; amount: number; date: string; currency: string }
      paymentInformation?: {
        __typename: 'PaymentInformation'
        paymentMethod: string
        lastFourDigits?: string | null
        expiryDate?: string | null
        cardType?: string | null
      } | null
      nextPayment?: {
        __typename: 'NextPayment'
        amount: number
        date: string
        currency: string
      } | null
    } | null
  }
}

export type MyPhotosTagCloudQueryVariables = Exact<{
  input: TagCloudFilter
}>

export type MyPhotosTagCloudQuery = {
  __typename: 'Query'
  myPhotosTagCloud: Array<{ __typename: 'CloudTag'; name: string; count: number }>
}

export type GetMessagesAvailableQueryVariables = Exact<{ [key: string]: never }>

export type GetMessagesAvailableQuery = {
  __typename: 'Query'
  getQueueMessagesAvailable: Array<{ __typename: 'MessagesAvailable'; x: number; y: number }>
}

export type OnUpdatePhotoSyncStatusSubscriptionVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type OnUpdatePhotoSyncStatusSubscription = {
  __typename: 'Subscription'
  onUpdatePhotoSyncStatus?: {
    __typename: 'SyncStatus'
    id: string
    total?: number | null
    success: number
    scheduled: number
    error: number
    state: SyncState
  } | null
}

export type OnUpdateUserCreditsSubscriptionVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type OnUpdateUserCreditsSubscription = {
  __typename: 'Subscription'
  onUpdateUserCredits?: {
    __typename: 'User'
    userId: string
    shares: { __typename: 'UserShares'; credits: number }
  } | null
}

export const SubscriptionStateFrFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStateFr' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionState' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFourDigits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionStateFrFragment, unknown>
export const GroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'group' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Group' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupFragment, unknown>
export const OauthVerifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'oauthVerify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oauthToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'oauthVerifier' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oauthVerify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oauthToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oauthToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oauthVerifier' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'oauthVerifier' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buddyIcon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OauthVerifyMutation, OauthVerifyMutationVariables>
export const ConfigureGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'configureGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useAnd' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configureGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groupId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'useAnd' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'useAnd' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configuration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useAnd' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfigureGroupMutation, ConfigureGroupMutationVariables>
export const DeleteScheduledPhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteScheduledPhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteScheduledPhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'photoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'photoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlSq' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteScheduledPhotoMutation, DeleteScheduledPhotoMutationVariables>
export const JoinGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'joinGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JoinGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMember' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configuration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'useAnd' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JoinGroupMutation, JoinGroupMutationVariables>
export const UpdatePhotoTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePhotoTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhotoTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'photoId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tags' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhotoTagsMutation, UpdatePhotoTagsMutationVariables>
export const UpsertUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertUserMutation, UpsertUserMutationVariables>
export const SyncPhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncPhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncPhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SyncPhotoMutation, SyncPhotoMutationVariables>
export const CancelSyncPhotoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelSyncPhoto' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelSyncPhoto' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelSyncPhotoMutation, CancelSyncPhotoMutationVariables>
export const UpdateUserSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionStateFr' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStateFr' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionState' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFourDigits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
>
export const UnsubscribeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unsubscribe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UnsubscribeInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unsubscribe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SubscriptionStateFr' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStateFr' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionState' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFourDigits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnsubscribeMutation, UnsubscribeMutationVariables>
export const BuyCreditsLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buyCreditsLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyCreditsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyCreditsLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyCreditsLinkMutation, BuyCreditsLinkMutationVariables>
export const TagCloudDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'tagCloud' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupTagsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tags' },
                value: { kind: 'ListValue', values: [] },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TagCloudQuery, TagCloudQueryVariables>
export const MyPhotosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myPhotos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhotoFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlSq' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sharedGroups' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalFavs' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syncStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyPhotosQuery, MyPhotosQueryVariables>
export const MyPhotoListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myPhotoList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhotoFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pages' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urlSq' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyPhotoListQuery, MyPhotoListQueryVariables>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buddyIcon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shares' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'credits' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const OauthUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'oauthUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OauthUrlInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oauthUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OauthUrlQuery, OauthUrlQueryVariables>
export const CheckUserIsValidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkUserIsValid' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buddyIcon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'realName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckUserIsValidQuery, CheckUserIsValidQueryVariables>
export const MyGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isMember' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'poolCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'safety' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eighteenPlus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photoLimitOptOut' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rules' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'throttle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remaining' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'useAnd' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyGroupsQuery, MyGroupsQueryVariables>
export const TagSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'tagSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tagSuggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'explanation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'matchingPhotos' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TagSuggestionsQuery, TagSuggestionsQueryVariables>
export const ScheduledPhotosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'scheduledPhotos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScheduledPhotosFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scheduledPhotos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scheduledPhotos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photoId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'urlSq' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isPublic' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScheduledPhotosQuery, ScheduledPhotosQueryVariables>
export const GroupTagsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupTagsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupTagsList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tags' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'count' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isMember' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'poolCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'photoLimitOptOut' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'configuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'useAnd' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'throttle' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'remaining' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupTagsListQuery, GroupTagsListQueryVariables>
export const SearchGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perPage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pages' } },
                { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'poolCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'members' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitationOnly' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photoLimitOptOut' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isMember' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rules' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'throttle' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'remaining' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'useAnd' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchGroupsQuery, SearchGroupsQueryVariables>
export const SuggestedTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'suggestedTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suggestedTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SuggestedTagsQuery, SuggestedTagsQueryVariables>
export const PhotoLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'photoLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PhotoLogsFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photoLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'photoLogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sk' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ScheduledPhotoLog' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'group' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'throttle' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'remaining' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SharePhotoErrorLog' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'errorCode' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'group' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SharePhotoSuccessLog' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'group' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'group' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'group' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Group' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhotoLogsQuery, PhotoLogsQueryVariables>
export const HydrateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hydrateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'hydrateGroup' },
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'photoLimitOptOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rules' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HydrateGroupQuery, HydrateGroupQueryVariables>
export const GetPhotoSyncStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPhotoSyncStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPhotoSyncStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'photoId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'photoId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPhotoSyncStatusQuery, GetPhotoSyncStatusQueryVariables>
export const UserSubscriptionInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userSubscriptionInformation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shares' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'credits' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionValidUntil' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionState' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SubscriptionStateFr' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubscriptionStateFr' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubscriptionState' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signupDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updateUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingPeriod' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modifiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isRecurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastFourDigits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserSubscriptionInformationQuery,
  UserSubscriptionInformationQueryVariables
>
export const MyPhotosTagCloudDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myPhotosTagCloud' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TagCloudFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myPhotosTagCloud' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyPhotosTagCloudQuery, MyPhotosTagCloudQueryVariables>
export const GetMessagesAvailableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMessagesAvailable' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getQueueMessagesAvailable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMessagesAvailableQuery, GetMessagesAvailableQueryVariables>
export const OnUpdatePhotoSyncStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'onUpdatePhotoSyncStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onUpdatePhotoSyncStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnUpdatePhotoSyncStatusSubscription,
  OnUpdatePhotoSyncStatusSubscriptionVariables
>
export const OnUpdateUserCreditsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'onUpdateUserCredits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onUpdateUserCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shares' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'credits' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OnUpdateUserCreditsSubscription,
  OnUpdateUserCreditsSubscriptionVariables
>
