<script lang="ts" setup>
  import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
  import { useLazyQuery } from '@vue/apollo-composable'
  import { computed } from 'vue'

  import { MeDocument } from '@/graphqlTypes'
  import { usePostHog } from '@/plugins/usePostHog'

  const { posthog } = usePostHog()

  const showBanner = computed(() => {
    return posthog.getFeatureFlag('feature-requests-canny') === 'test'
  })

  const { onResult, start } = useLazyQuery(MeDocument)
  if (showBanner.value) {
    start()
  }

  onResult(async (result) => {
    if (!result.loading) {
      const { userName, email, userId } = result.data.me
      await window.Canny('identify', {
        appID: '6568cc3d712af2fd071ec4c8',
        user: {
          name: userName,
          id: userId,
          email: email,
        },
      })
    }
  })
</script>

<template>
  <BaseBanner v-if="showBanner" ref="banner" dismissable class="text-sm leading-6 items-center">
    <template #icon><QuestionMarkCircleIcon /></template>
    <strong class="font-semibold">We value your feedback! Submit and vote on feature ideas.</strong>
    <br />
    Access
    <strong>Feature requests</strong>
    in the user menu and share your thoughts!
  </BaseBanner>
</template>
